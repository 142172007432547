export default {
  hero: {
    notificationText1: "Töitä",
    notificationText2: "Katso mitä olemme tehneet aiemmin",
    title1of2: "Mobiilikehitys",
    title2of2: "palvelut",
    content:
      "Mobiilisovellus voi toimia tuotteena, jota myydään Google Play tai Applen App Store kaupassa. Palveluna, josta laskutetaan kuukausittain tai täysin ilmaisena palveluna ja alustana sitouttamaan käyttäjiä.",
  },
  mobile: {
    subtile: "Mobiilikehitys",
    title: "Mobiilisovelluskehitys",
    text1of2:
      "Ihmiset käyttävät 69% media-ajastaan älypuhelimilla. Nopeasti kasvava mobiililaitteiden käyttö luo hyvän alustan erilaisille palveluille ja tuotteille. Mobiilisovellus voi toimia tuotteena, jota myydään Google Play tai App Store kaupassa tai palveluna, josta laskutetaan kuukausittain tai täysin ilmaisena palveluna ja alustana sitouttamaan käyttäjiä. Mobiiliapplikaatioita voidaan käyttää kuin kauppa-alustana kuten eBay.",
    text2of2:
      "Viime vuosina mobiilikehitys on muuttunut ja kehittynyt mobiililaitteiden käytön yleistyessä. Tällä hetkellä ohjelmistoja voidaan kehittää natiivina Android ja iOS alustoille. Viime vuosina kuitenkin kiinnostus hybrid-sovellusten mahdollisuuksista ja niiden kehityksestä on lisääntynyt. Uusimpana tulokkaana on React Native, joka on ollut mukana mobiiliapplikaatioiden kehityksessä vasta viimeisen vuoden.",
  },
  platforms: {
    title: "Mobiilisovelluskehityksen alustat ja tekniikat",
    text1of2:
      "Viime vuonna mobiilisovelluksissa Android ja iOS alustojen markkina-asema kasvoi 99%:sta 99.7%:iin. Varteenotettavia kilpailijoita ei ole tällä hetkellä, eikä oikeastaan ole näköpiirissäkään.",
    text2of2:
      "Koska kumpikin alusta on kehitetty eri ohjelmointikielillä, natiivissa ohjelmistokehityksessä ei pystytä käyttämään samaa koodia molemmille laitteille, mikä tarkoittaa sitä, että jos mobiilisovellus kehitetään iOS ja Android ympäristöihin niin molempiin on kirjoitettava oma ohjelmisto. Hybrid-ohjelmistot tuovat yhden ratkaisun tähän ongelmaan ja mahdollistavat web-teknologioiden käytön kummassakin laitteessa, jolloin ohjelmistokoodi toimii kummassakin alustassa. Viime vuonna mukaan tuli React Native, joka mahdollistaa React ohjelmistokehyksen käytön ja yhteisen koodikielen käytön kummassakin alustassa.",
  },
  iosAndroid: {
    ios: {
      title: "iOS-mobiilisovelluskehitys",
      text: "iOS on Applen kehittämä mobiilikäyttöjärjestelmä, jolla on 12.7% markkinaosuus ja se on Suomen toiseksi käytetyin käyttöjärjestelmä, mutta se on käytetyin mobiilikäyttöjärjestelmä Ruotsissa, Norjassa, Yhdysvalloissa, Japanissa, Kanadassa, Tanskassa, Ranskassa, Belgiassa, Iso-Britanniassa, Irlannissa ja Australiassa.",

      iosFeatures: [
        {
          title: "Keskiverto iOS-käyttäjä:",
          listItem: [
            "Asuu Pohjois-Amerikassa tai Länsi-Euroopassa.",
            "Työskentelee luovalla alalla tai yrittäjänä.",
            "Käyttää enemmän rahaa internetissä ja mobiilisovelluksissa.",
            "Käyttää eniten sovelluksia seuraavissa kategorioissa: Business, koulutus ja elämäntyyli.",
            "Tekee todennäköisemmin ostoksia applikaation sisällä.",
          ],
        },
        {
          title: "iOS-sovelluskehityksen hyvät puolet:",
          listItem: [
            "Vahva markkina-asema Yhdysvalloissa ja Euroopassa.",
            "Tuottaa keskivertoisesti enemmän rahaa.",
            "Vähemmän mahdollisia bugeja.",
          ],
        },
        {
          title: "iOS-sovelluskehityksen huonot puolet:",
          listItem: [
            "iOS-käyttöjärjestelmällä on puolet vähemmän käyttäjiä.",
            "Vaikeampi julkaista applikaatio.",
            "Tuottaa vähemmän liikevaihtoa sovelluksen sisäisissä mainoksissa.",
          ],
        },
      ],
    },

    android: {
      title: "Android mobiilisovelluskehitys",
      text: "Googlen kehittämä Android-käyttöjärjestelmä pohjautuu Linux käyttöjärjestelmään. Android-käyttöjärjestelmällä on 87% markkinaosuus ja se on Suomen yleisin mobiililaitteen käyttöjärjestelmä. Maailmanlaajuisestikaan se ei häviä iOS käyttöjärjestelmälle kuin Ruotsissa, Norjassa, Tanskassa, Yhdysvalloissa, Kanadassa, Iso-Britanniassa, Irlannissa, Ranskassa, Belgiassa, Japanissa ja Australiassa. Android toimii mobiililaitteiden lisäksi autoissa, televisioissa ja älykelloissa.",
      androidFeatures: [
        {
          title: "Keskiverto Android-käyttäjä:",
          listItem: [
            "Asuu Etelä-Amerikassa, Aasiassa, Keski- tai Itä-Euroopassa.",
            "Toimii työkseen tekniikan parissa.",
            "Arvostaa hinta-laatusuhdetta.",
            "Huomioi eniten applikaatioita seuraavissa kategorioissa: Työkalut, viihde ja kommunikaatio.",
            "Huomioi ohjelmiston sisäiset mainokset paremmin.",
          ],
        },
        {
          title: "Android-sovelluskehityksen hyvät puolet:",
          listItem: [
            "Suuremmat markkinat.",
            "Enemmän applikaatioita.",
            "Nopeampi ja kevyempi julkaisuprosessi.",
            "Enemmän liikevaihtoa sovelluksen sisäisissä mainoksissa.",
          ],
        },
        {
          title: "Android-sovelluskehityksen huonot puolet:",
          listItem: [
            "Android-sovellukset tuottavat vähemmän liikevaihtoa.",
            "Enemmän potentiaalisia bugeja.",
          ],
        },
      ],
    },
  },
}
