import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import data from "../content/mobiilikehitys"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title={data.hero.title1of2}
        description="Tuotamme applikaatioita nopeasti ja kustannustehokkaasti. Suunnittelu onnistuu Android, kuin iOS alustoille natiivi, kuin hybrid menetelmin."
        pageImage={metaImg}
        pageUrl={links.mobile}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <span className="block">
              <h1>{data.hero.title1of2}</h1>
            </span>
            <span className="block text-indigo-400">{data.hero.title2of2}</span>
          </HeroTitle>
          <HeroLead>{data.hero.content}</HeroLead>
        </Hero>
      </section>

      <section id="mobile" className="pt-12 sm:pt-40 lg:pt-0">
        <DotSection dotPosition="top-right">
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div className="lg:col-span-1">
              <SubTitle tag="p">{data.mobile.subtile}</SubTitle>
              <Title tag="h2">{data.mobile.title}</Title>
              <Text>{data.mobile.text1of2}</Text>
              <Text>{data.mobile.text2of2}</Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/1-right-trans-color.svg"
                alt="Profiilikuva hehkulampun päällä"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="platforms" className="bg-gray-800">
        <Container>
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div>
              <StaticImage
                src="../images/2-left-trans.svg"
                alt="Applikaatio layoutit"
              />
            </div>
            <div>
              <Title tag="h2" textColor="text-white">
                {data.platforms.title}
              </Title>
              <Text textColor="text-white">{data.platforms.text1of2}</Text>
              <Text textColor="text-white">{data.platforms.text2of2}</Text>
            </div>
          </div>
        </Container>
      </section>

      <section id="ios-android">
        <Container>
          <div className="grid lg:grid-cols-2 space-x-5">
            <div>
              <SubTitle tag="p">Apple</SubTitle>
              <Title tag="h3">{data.iosAndroid.ios.title}</Title>
              <Text>{data.iosAndroid.ios.text}</Text>
              {data.iosAndroid.ios.iosFeatures.map(feature => (
                <div className="pt-8" key={feature.title}>
                  <SubTitle tag="p">{feature.title}</SubTitle>
                  <BasicList>
                    {feature.listItem.map(item => (
                      <li className="mt-2" key={item}>
                        {item}
                      </li>
                    ))}
                  </BasicList>
                </div>
              ))}
            </div>
            <div className="mt-10 md:mt-0">
              <SubTitle tag="p">Google</SubTitle>
              <Title tag="h3">{data.iosAndroid.android.title}</Title>
              <Text>{data.iosAndroid.android.text}</Text>
              {data.iosAndroid.android.androidFeatures.map(feature => (
                <div className="pt-8" key={feature.title}>
                  <SubTitle tag="p">{feature.title}</SubTitle>
                  <BasicList>
                    {feature.listItem.map(item => (
                      <li className="mt-2" key={item}>
                        {item}
                      </li>
                    ))}
                  </BasicList>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>

      <section id="hybrid">
        <Container>
          <Title tag="h3">Hybrid-mobiilisovelluskehitys</Title>
          <Text>
            Mikäli yrityksen tarkoituksena on julkaista mobiilisovellus sekä
            Android- ja iOS-alustoille, Hybrid-sovellus on halvin tapa valmistaa
            sovellus, koska sama koodi toimii kummassakin laitteessa.
          </Text>
          <Text>Huom!</Text>
          <Text>
            Aiemmin kerroimme joitakin hyviä ja huonoja puolia
            Hybrid-sovelluksista, mutta jätämme käsittelystä pois React
            Native:n, koska uusin tulokas vaikeuttaa hieman Hybrid-sovelluksen
            käsitettä. Kirjoitimme React Native sovelluskehityksestä täysin oman
            osuuden, johon pääset <Link to="#react">tästä</Link>. Tällä hetkellä
            suosittelemme kaikille React Native:a vanhemman teknologian
            Hybrid-sovelluksen sijaan.
          </Text>
        </Container>
      </section>

      <section id="what-is-hybrid">
        <Container>
          <Title tag="h4">Mitä Hybrid-applikaatiolla tarkoitetaan?</Title>
          <Text>
            Hybrid-applikaatiolla tarkoitetaan sovellusta, joka kehitetään
            samalla tavalla kuin nettisivut. Kummassakin käytetään teknologioita
            kuten HTML, CSS ja JavaScript. Erona natiivisovellukseen on, että
            Hybrid-applikaatio toimii WebView-kehyksessä, joka on kuin
            sovelluksen sisällä oleva näkymätön selain. Hybrid-applikaatiot
            voivat olla yhteydessä puhelimen ominaisuuksiin. Hybrid-applikaatiot
            valmistetaan yleensä
            <a href="https://cordova.apache.org/">Apache Cordova</a>,{" "}
            <a href="https://ionicframework.com/">Ionic</a> tai{" "}
            <a href="https://reactnative.dev/">React Nativella</a>.
          </Text>
        </Container>
      </section>

      <section id="when-choose-hybrid">
        <Container>
          <Title tag="h4">Milloin valita Hybrid-applikaatio?</Title>
          <Text>
            Kun mietit milloin kannattaa valita hybrid-mobiilisovellus, on hyvä
            ottaa huomioon muutamia seikkoja.
          </Text>
          <ul>
            <li>
              <Text>
                Suurin hyöty Hybrid-applikaatioissa on yhden projektin
                kehittäminen, joka toimii kummassakin laitteessa.
              </Text>
            </li>
            <li>
              <Text>
                Mikäli Hybrid-sovellus on erittäin hyvin tehty, on mahdotonta
                sanoa eroa natiivisovelluksiin.
              </Text>
            </li>
            <li>
              <Text>
                Natiivisovellus on optimoitu yhdelle alustalle ja on siksi
                nopeampi sekä sillä pystyy käyttämään kaikkia mobiilialustan
                ominaisuuksia, siinä missä Hybrid-sovellus on lähtökohtaisesti
                hitaampi ja sillä on hankaluuksia käsitellä raskasta dataa,
                näyttävää grafiikkaa tai paljon mediaa.
              </Text>
            </li>
          </ul>
        </Container>
      </section>

      <section id="react-native">
        <Container>
          {/* TODO: ei toimi tasta nappula */}
          <div id="#react">
            <Title tag="h3">React Native-mobiilisovelluskehitys</Title>
          </div>
          <Text>
            <a href="https://reactnative.dev/">React Native</a> on
            mobiilikehityksen kuumin aihe. Facebook julkaisi React Nativen 2015
            ja sen tarkoituksena oli yhtenäistää iOS- ja Android-mobiilikehitys.
            React Native-kehitys toimii samalla tavalla kuin Hybrid-sovellusten
            kehitys sillä isolla erolla, että React Native-sovellusten lopuksi
            Webview:n sijaan ohjelma pyörii natiivina sovelluksena, jolloin sen
            tehokkuus ja ominaisuudet ovat lähes verrattavissa
            natiivisovelluksiin.
          </Text>
          <Text>
            Kaikkien hienojen ja mullistavien ominaisuuksien lisäksi React
            Native on todella nuori teknologia, ja siksi on vielä vaikea
            ennustaa pitkällä tähtäimellä, mihin kehitys vie uuden teknologian.
            Tosin edullisemman kehityksen takia, riski on useassa projektissa
            taloudellisesti kannattava.
          </Text>
        </Container>
      </section>

      <section id="cloud" className="bg-gray-800">
        <div>
          <Container>
            <div className="grid lg:grid-cols-2 text-white space-x-5">
              <div className="mx-auto mb-6 lg:mb-0">
                <StaticImage
                  src="../images/5-left-trans.svg"
                  alt="Profiili raketin ympärillä"
                />
              </div>
              <div>
                <Title tag="h3" textColor="text-white">
                  Pilvipalvelut
                </Title>
                <Text textColor="text-white">
                  Suurin osa mobiilisovelluksista tarvitsee nykyään internet- ja
                  pilviyhteyden. Mobiilisovellus voidaan kytkeä olemassa olevaan
                  ohjelmistoon, joka tukee HTTP tai Websocket yhteyksiä.
                  Mobiilisovelluskehityksen yhteydessä olemassa olevaan
                  pilvipalveluun kuitenkin usein tarvitaan muutoksia tehokkuuden
                  tai ominaisuuksien parantamiseksi. Pilvipalvelu voidaan
                  valmistaa myös mobiilisovellukselle mittatilaustyönä. Riippuen
                  hieman siitä millaisen palveluntarjoajan haluat projektillesi.
                </Text>
                <Text textColor="text-white">
                  Teemme yhteistyötä suurimpien ja suosituimpien palvelin- ja
                  BaaS-palveluntarjoajien kanssa ja autamme mielellämme
                  valitsemaan projektille oikean palvelimen.
                </Text>
                <Text textColor="text-white">
                  <Link to={links.software}>
                    Tutustu ohjelmistokehitykseen!
                  </Link>
                </Text>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section id="publishing-and-marketing">
        <Container>
          <div className="text-center">
            <Title tag="h2">Julkaisu ja Markkinointi</Title>
            <Text>
              Sovelluskehityksen jälkeen sovelluksille, jotka eivät päädy vain
              yrityksen omaan käyttöön, vastaan tulee julkaisu App Storessa
              ja/tai Google Playssa. Autamme julkaisemaan projektin kauppoihin
              ja voimme auttaa myös julkaisujen digitaalisessa markkinoinnissa.
            </Text>
          </div>
        </Container>
      </section>

      <section id="application-publishing">
        <Container>
          <div>
            <div>
              <Title tag="h3">Applikaatiojulkaisu</Title>
              {/* TODO: id ei toimi */}
              <Text>
                Kummassakin kauppapaikassa on hyvin erilaiset kohderyhmät.
                Applikaatioiden julkaisussa on huomioitava minkälaiset
                sovellukset yleensä tuottavat molemmissa kauppapaikoissa.
                Ylempänä kävimme hieman läpi minkälaisia käyttäjiä kummallakin
                alustalla on, mikäli ohitit alustojen yleisimmät kohderyhmä
                pääset sinne <Link to="#platforms">tästä</Link>.
              </Text>
            </div>
            <div className="grid lg:grid-cols-2 lg:space-x-5">
              <div className="mt-10 md:mt-5">
                <SubTitle tag="h4">Google Play</SubTitle>
                <Text>
                  Android laitteita on neljä kertaa enemmän kuin iOS laitteita.
                  Google Play kaupassa applikaatio todennäköisemmin tuottaa
                  enemmän rahaa mobiilisovelluksen sisäisillä mainoksilla.
                </Text>
                <Text>
                  Google Play on julkaisuprosessina nopeampi. Julkaisussa
                  vaaditaan, että applikaatio vain ladataan Google Play kauppaan
                  ja täytetään tiedot sovelluksesta.
                </Text>
              </div>
              <div className="mt-10 md:mt-5">
                <SubTitle tag="h4">App Store</SubTitle>
                <Text>
                  Vaikka iOS-laitteita on neljä kertaa vähemmän kuin Androidin
                  vastaavia, iOS sovellukset tekevät 75 % enemmän rahaa kuin
                  Androidin vastaavat. Morgan Stanley -tutkimuksen mukaan 92 %
                  iPhonen käyttäjistä todennäköisesti valitsee seuraavaksi
                  puhelimekseen myös iPhonen.
                </Text>
                <Text>
                  App Store -julkaisu on kuitenkin hieman hitaampi prosessi kuin
                  Google Play kaupan julkaisut. Jokainen App Storeen julkaistava
                  ohjelma lähetetään Applelle arvosteltavaksi. Siellä Applen
                  edustaja arvioi, täyttääkö mobiilisovellus tarvittavat
                  laatukriteerit.
                </Text>
              </div>
            </div>
            <div className="mt-10 md:mt-5">
              <SubTitle tag="h4">Yksityinen julkaisu</SubTitle>
              <Text>
                Jokaista mobiilisovellusta ei tarvitse julkaista julkisessa
                kaupassa. Mobiilisovellus voidaan myös asettaa käytettäväksi
                esimerkiksi yrityksen sisäiseen käyttöön. Tällöin
                mobiilisovelluksen julkaisu on täysin kiinni yrityksestä
                itsestään.
              </Text>
            </div>
          </div>
        </Container>
      </section>

      <section id="digital-marketing">
        <Container>
          <div>
            <Title tag="h3">Digitaalinen markkinointi</Title>
            <Text>
              Julkaisun jälkeen mobiilisovelluksen matka vasta alkaa ja tässä
              vaiheessa on tärkeää, että mobiilisovelluksella olisi arvoisensa
              markkinointikampanja ja tiimi. Autamme mielellämme markkinoimaan
              sovelluksia digitaalisessa ympäristössä, aina Instagramista
              AdWordsiin.
            </Text>
          </div>
        </Container>
      </section>

      <section id="key-to-success">
        <Container>
          <div>
            <Title tag="h2">Avain onnistumiseen</Title>
            <Text>
              Kun yhdistämme asiakasymmärryksen, käytettävyyden ja teknisen
              toteutuksen saavutamme mobiilikeskeisen digitaalisen ratkaisun. On
              osattava olla vuorovaikutuksessa asiakkaan, toteuttajan ja
              loppukäyttäjän välillä. Me tulemme kysymään, me tulemme
              kokeilemaan ja me tulemme kuuntelemaan saavuttaaksemme halutun
              lopputuloksen.
            </Text>
            <Text>
              Hyvin rakennettu mobiilisovellus antaa käyttäjilleen hyvän
              käyttäjäkokemuksen, jolloin palvelu toimii käyttäjän ehdoilla. Jos
              mobiilisovellus on huonosti rakennettu ja siinä on sekava
              käyttöliittymä saattaa se vieraannuttaa käyttäjän ja myöhemmin
              sinun on hankala saada kertaalleen menetetty käyttäjä takaisin.
              Siksi on erityisen tärkeää saada käyttäjille alusta asti hyvä
              käyttäjäkokemus ja sen lisäksi jos sovellus on hyvin suunniteltu
              ja rakennettu saattavat sen käyttäjät kertoa omille ystävilleen
              sovelluksesta mikä on todella hyvää markkinointia
              mobiilisovellukselle.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Suunnittelu</SubTitle>
            <Text>
              Suunnittelemme perusteellisesti sinun kanssasi mobiilisovelluksen
              konseptin, jonka jälkeen siirrymme suunnittelemaan
              käyttöliittymän. Tarvittaessa teemme myös prototyypin
              sovelluksesta.Tämän jälkeen aloitamme teknisen taustajärjestelmän
              suunnittelun ja mobiilikehityksen. Nämä ovat avain siihen, että
              tulemme onnistumaan ja saavuttamaan halutun lopputuloksen.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Kehitys</SubTitle>
            <Text>
              Mobiilikehitys lähtee liikkeelle yhteistyössä teidän kanssanne.
              Kehitämme mobiilisovellukset iOS ja Android alustoille, sekä
              taustajärjestelmän ja sen rajapinnan välille. Valitsemme
              mobiilisovelluksen toteutustavaksi joko hybrid- tai natiivi
              teknologian.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Testaus</SubTitle>
            <Text>
              Mobiilisovelluksen testaamisen voit jättää meidän huoleksemme tai
              voit tehdä sen testiryhmäsi avulla. Sovelluksen testaaminen on
              tärkeää sillä sen avulla löydämme mahdolliset bugit ennen
              mobiilisovelluksen julkaisemista. Sovelluksen taustajärjestelmän
              testauksessa on tärkeää integraatiotestaus ja kuormituskyvyn
              mittaus. Käyttäjille tämä vaihe tulee näkymään mobiilisovelluksen
              laatuna – parempi korjata bugit etukäteen kuin jälkeenpäin.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Ylläpito</SubTitle>
            <Text>
              Emme jätä teitä tyhjän päälle mobiilisovelluksen julkaisemisen
              jälkeen. Jos sinulle tulee kysyttävää sen jälkeen kun sovellus on
              julkaistu autamme siinä mielellämme. Kerromme sinulle mitä ottaa
              huomioon julkaisemisen jälkeen, miten ylläpitää sovellusta ja
              tarvittaessa autamme sinua myös saamaan mobiilisovellus
              sovelluskauppoihin ja voimme auttaa sovelluksen markkinoinnissa.
              Et siis tule jäämään yksin vaan tulemme auttamaan sinua kaikessa
              mikä liittyy sovellukseesi jos sinulle tulee siihen kysyttävää!
            </Text>
            <Text>
              Jos sinulla on idea mobiilisovelluksesta niin ota rohkeasti meihin
              yhteyttä – lähdetään yhdessä suunnittelemaan sinulle sovellus!
            </Text>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
